
export default {
  namespaced: true,

  state: {
    from_required_date: null,
    to_required_date: null,
    status: null,
    unit: null,
    area: null,
    requester: null,
    approver: null,
    discipline: null,
    standing: 1,
    same_domain: 0,
    per_page: 50,  
    page: 1,
    last_request_visited: null,
  },

  getters: {
    from_required_date (state) {
        return state.from_required_date
    },
    to_required_date (state) {
        return state.to_required_date
    },
    status (state) {
        return state.status
    },
    unit (state) {
      return state.unit;
    },
    area (state) {
      return state.area;
    },
    requester (state) {
      return state.requester;
    },
    approver (state) {
      return state.approver;
    },
    discipline (state) {
      return state.discipline;
    },
    standing (state) {
      return state.standing;
    },
    same_domain (state) {
      return state.same_domain;
    },
    per_page (state) {
      return state.per_page;
    },
    page (state) {
      return state.page;
    },
    last_request_visited (state) {
      return state.last_request_visited;
    },
  

  },

  mutations: {
    SET_FROM_REQUIRED_DATE (state, value) {
        state.from_required_date = value
    },
    SET_TO_REQUIRED_DATE (state, value) {
        state.to_required_date = value
    },
    SET_STATUS (state, value) {
        state.status = value
    },
    SET_UNIT (state, value) {
      state.unit = value
    },
    SET_AREA (state, value) {
      state.area = value
    },
    SET_REQUESTER (state, value) {
      state.requester = value
    },
    SET_APPROVER (state, value) {
      state.approver = value
    },
    SET_DISCIPLINE (state, value) {
      state.discipline = value
    },
    SET_STANDING (state, value) {
      state.standing = value
    },
    SET_SAME_DOMAIN (state, value) {
      state.same_domain = value
    },
    SET_PER_PAGE (state, value) {
      state.per_page = value
    },
    SET_PAGE (state, value) {
      state.page = value
    },
    SET_LAST_REQUEST_VISITED (state, value) {
      state.last_request_visited = value
    },


  },


  actions: {
  

  }  
}
